.memberHeader .navbar {
  margin-top: 20px;
  background-color: white;
  height: 60px;
  padding: 5px;
}

.memberHeader .logo {
  width: 70vw;
  max-width: 220px;
}

.memberHeader a {
  color: #23333a !important;
  text-decoration: none;
  display: inline-block;
  padding: 15px 20px;
  position: relative;
}

.memberHeader .l100 {
  right: 0px !important;
}

.navbar-nav {
  margin-left: 15px;
}

.memberHeader .userImage {
  height: 44px;
  width: 34px;
  margin-left: 10px;
}
