.keywordSearch .keyword {
  background-color: lightgray;
  padding: 0px 5px 0px 5px;
  border: 1px solid lightgray;
  font-size: 20px;
}

.keywordSearch .delete {
  display: inline-block;
  font-weight: bold;
  cursor: pointer;
}

.keywordSearch .highlightedToDelete {
  border: 1px solid red;
  color: red;
}

.keywordArea {
  margin-bottom: 5px;
}

.keywordSearch .searchArea {
  position: relative;
}

.keywordSearch .searchResults {
  position: absolute;
  top: 30px;
  border: 1px solid gray;
  background-color: rgba(255, 255, 255, 0.8);
}

.keywordSearch .searchResultItem {
  padding: 0px 5px 0px 5px;
}

.keywordSearch .searchResultItem:hover {
  cursor: pointer;
  background-color: lightblue;
}
