.backgroundAnimation {
  width: 100%;
  height: 100%;
  position: relative;
}

.backgroundAnimation img {
  width: 100%;
  height: 100%;
}

.backgroundAnimation canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
}
