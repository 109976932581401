.specialOfferDiv {
  margin-top: 20px;
  padding: 15px;
  text-align: center;
  font-size: 30px;
  background-color: rgba(255, 0, 0, 0.1);
  border: 2px solid red;
  width: 100%;
  max-width: 800px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.mediumText {
  font-size: 20px;
}

.bookNow {
  width: 100%;
  font-size: 30px;
  border-radius: 10px;
}
