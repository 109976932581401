.LensAndToolEditor {
}

.whitePanel {
  background-color: white;
  text-align: left;
  padding: 10px;
}

.headerImage {
  width: 40px;
}
