.GenericPopup {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  z-index: 200;
}

.GenericPopupInner {
  width: 100%;
  height: 100%;
  display: block;
}

.GenericPopup .header {
  padding: 10px;
  height: 60px;
  line-height: 30px;
}

.GenericPopup .header .title {
  color: white;
  font-weight: bold;
  font-size: 30px;
}

.GenericPopup .header button {
}

.GenericPopup .bodyArea {
  padding-top: 10px;
  background: lightgray;
  /* Firefox */
  height: -moz-calc(100% - 60px);
  /* WebKit */
  height: -webkit-calc(100% - 60px);
  /* Opera */
  height: -o-calc(100% - 60px);
  /* Standard */
  height: calc(100% - 60px);
}

.GenericPopup .card {
  margin-bottom: 10px;
}

.fullWidth {
  width: 100%;
  box-sizing: border-box;
}

.section {
  margin-bottom: 10px;
}

.section textarea {
  height: 150px;
}

.selected-node {
  border: 5px solid blue;
}
