.pageHeader,
.pageFooter {
  color: white;
  margin-bottom: 20px;
}

.pageFooter {
  margin: 20px;
}

.emptySpace {
  height: 150px;
}

.pageFooter img {
  width: 30px;
}

.pageSubheader {
  color: white;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
}

.page {
  color: rgb(200, 200, 200);
}

.blueSection {
  position: relative;
  color: rgb(200, 200, 200);
  text-align: left;
  background-color: rgba(6, 14, 46, 0.7);
}

.blueSection2 {
  position: relative;
  color: rgb(200, 200, 200);
  text-align: left;
}

.lightblueSection {
  position: relative;
  color: rgb(200, 200, 200);
  text-align: left;
  background-color: rgba(110, 138, 150, 0.7);
}

.whiteSection {
  padding: 10px;
  color: rgb(50, 50, 50);
  text-align: left;
  background-color: rgba(255, 255, 255, 0.7);
}

.bluePage {
  padding-top: 10px;
  color: rgb(200, 200, 200);
  text-align: left;
  background-color: rgba(34, 51, 57, 0.7);
  border-top: 3px solid rgb(34, 51, 57);
}

.lightbluePage {
  padding-top: 10px;
  color: rgb(200, 200, 200);
  text-align: left;
  background-color: rgba(110, 138, 150, 0.7);
  border-top: 3px solid rgb(110, 138, 150);
}

.whiteBackground {
  margin-top: 20px;
  padding: 15px 0px 15px 0px;
  color: #23333a;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.7);
  border-bottom: 3px solid #ff4a21;
  border-top: 3px solid white;
}

.whiteBackground h1,
.whiteBackground h2,
.whiteBackground h3,
.whiteBackground h4 {
  color: #23333a !important;
}

.profileImage {
  text-align: right;
}

.profileImage img {
  width: 100%;
  max-width: 150px;
}

.page h1,
.page h2,
.page h3,
.page h4 {
  color: white;
}

.footerText {
  font-family: "Raleway";
  font-size: 30px;
  margin-right: 15px;
  color: white;
}

.footerText img {
  height: 35px;
}

.highlighted {
  font-weight: bold;
  color: white;
}

.profileText {
  text-align: justify;
}

.shadowImage {
  border: 1px solid gray;
}

.mountainImage {
  background-image: url("../images/mountain.jpg");
  background-size: cover;
  background-position: center;
  opacity: 1;
}

.compassImage {
  background-image: url("../images/compass.jpg");
  background-size: cover;
  background-position: center;
  opacity: 1;
}

.creativeTeamImage {
  background-image: url("../images/creativeteam.jpg");
  background-size: cover;
  background-position: center;
  opacity: 1;
}

.frontPageMainImage {
  width: 30%;
  display: table-cell;
}

.headerMenu ul {
  margin-top: 10px;
  padding-left: 5px;
  margin-bottom: 5px;
}

.headerMenu li {
  display: block;
  margin: 0px 5px 0px 5px;
}

.frontPageContent {
  font-size: 17.3px;
  line-height: 21px;
  opacity: 1;
}

.image-and-content {
  background: white;
  position: relative;
}

.image-and-content__image {
  height: 260px;
}

.pictureColumnBox__picture {
  width: 100%;
  height: 260px;
}

.pictureColumnBox__content {
  padding: 20px 15px 20px 15px;
}

.orangeBox__content {
  padding: 10px;
}

@media screen and (min-width: 576px) {
  .image-and-content__container {
    width: 540px;
  }
}

@media screen and (min-width: 768px) {
  .image-and-content__container {
    width: 720px;
  }

  .orangeBox {
    position: relative;
    height: 400px;
  }

  .orangeBox2 {
    position: relative;
    height: 500px;
  }

  .orangeBox__image {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    width: 70%;
    background-color: red;
  }

  .orangeBox__content {
    position: absolute;
    padding: 10px;
    top: 0px;
    bottom: 0px;
    padding-right: 100px;
    left: 0px;
    width: 70%;
    height: 100%;
    background: linear-gradient(90deg, white 70%, rgba(255, 255, 255, 0) 100%);
  }
}

@media screen and (min-width: 992px) {
  .image-and-content__container {
    width: 960px;
  }

  .orangeBox {
    position: relative;
    height: 280px;
  }

  .orangeBox2 {
    position: relative;
    height: 350px;
  }
}

@media screen and (min-width: 1200px) {
  .image-and-content {
    height: 400px;
  }

  .image-and-content__container {
    width: 1140px;
    height: 100%;
  }

  .image-and-content__content {
    padding-top: 150px;
    position: absolute;
    height: 100%;
    width: 700px;
    padding-right: 100px;
  }

  .image-and-content__content_full {
    position: absolute;
    height: 100%;
  }

  .image-and-content__cell {
    color: rgb(0, 0, 0);
    background: white;
    background: linear-gradient(90deg, white 60%, rgba(255, 255, 255, 0) 100%);
    padding-top: 20px;
    padding-bottom: 40px;
    text-shadow: 0px 0px 2px #ffffff;
  }

  .image-and-content__cell2 {
    color: rgb(0, 0, 0);
    padding-top: 20px;
    padding-bottom: 40px;
    text-shadow: 0px 0px 4px #ffffff;
  }

  .image-and-content__content2 {
    padding-top: 150px;
    position: absolute;
    height: 100%;
    width: 700px;
    padding-right: 100px;
  }

  .image-and-content__image {
    height: 400px;
    position: absolute;
    right: 0;
    width: calc(100% - 800px);
    min-width: 1000px;
    max-width: 1200px;
  }

  .pictureColumnBox__picture {
    position: absolute;
    top: 0px;
    left: 0px;
    width: calc((100% - 450px) / 2);
    height: 340px;
    float: left;
  }

  .pictureColumnBox__content {
    width: 70%;
    margin-left: 30%;
    padding: 30px 10px 0px 30px;
    height: 340px;
  }

  .orangeBox {
    position: relative;
    height: 280px;
  }

  .orangeBox2 {
    position: relative;
    height: 280px;
  }
}

.image-and-content__container {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

.paddedEdges {
  padding-left: 15px;
  padding-right: 15px;
}

.paddedTopAndBottom {
  padding-top: 20px;
  padding-bottom: 20px;
}

.image-and-content__content {
  color: rgb(50, 50, 50);
  background: white;
  background: linear-gradient(90deg, white 60%, rgba(255, 255, 255, 0) 100%);
  padding-top: 20px;
  padding-bottom: 40px;
  text-shadow: 0px 0px 2px #ffffff;
}

.image-and-content__content2 {
  color: rgb(200, 200, 200);
  background: rgb(6, 14, 46);
  background: linear-gradient(
    90deg,
    rgb(6, 14, 46) 60%,
    rgba(6, 14, 46, 0) 100%
  );
  padding-top: 20px;
  padding-bottom: 40px;
  text-shadow: 0px 0px 2px #00000000;
}

.image-and-content__image img {
  object-fit: cover;
  font-family: "object-fit: cover;";
  height: 100%;
  width: 100%;
}

.whiteBox {
  color: rgb(200, 200, 200);
}

.pictureColumnBox__picture img {
  object-fit: cover;
  font-family: "object-fit: cover;";
  height: 100%;
  width: 100%;
}

.blackBox {
  color: rgb(50, 50, 50);
  border: 3px solid orange;
  margin-top: 20px;
  margin-bottom: 20px;
}

.image-and-content h4,
.blackBox h4 {
  font-weight: bold;
  color: #406a9e;
}

.whiteBox h4 {
  font-weight: bold;
  color: white;
}

.image-and-content .highlighted,
.blackBox .highlighted {
  color: black;
}

.whiteBox .highlighted {
  color: white;
}

.orangeBox__image img {
  object-fit: cover;
  font-family: "object-fit: cover;";
  height: 100%;
  width: 100%;
}

.blackOnWhiteText {
  color: rgb(50, 50, 50);
  position: relative;
  background-color: white;
}

.blackOnWhiteText .highlighted {
  color: black;
  font-weight: bold;
}

.blackOnWhiteText h4 {
  font-weight: bold;
  color: #406a9e;
}

.lowerButton {
  padding: 10px;
  color: white;
  font-weight: bold;
  background: #406a9e;
  text-align: center;
  cursor: pointer;
  margin-bottom: 10px;
}

.lowerButton:hover {
  background: #264e7e;
}

.whiteOnBlackText {
  color: rgb(200, 200, 200);
  position: relative;
  background-color: black;
}

.whiteOnBlackText .highlighted,
.image-and-content__content2 .highlighted {
  color: white;
  font-weight: bold;
}

.image-and-content__content2 h4 {
  font-weight: bold;
  color: white;
}

.whiteOnBlackText h4,
.whiteOnBlackText h1 {
  font-weight: bold;
  color: white;
}

.whiteOnBlackText a {
  color: white;
}

.topMargin {
  margin-top: 10px;
}

.submitButton {
  text-align: center;
}

.submitButton button {
  border: 1px solid white;
  font-weight: bold;
  font-size: 20px;
}

.smallUMargin {
  margin: 10px 5px 0px 5px;
  box-sizing: border-box;
}

.centredTextBox {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 700px;
  text-align: center;
  padding: 0px 5px 0px 5px;
}

ul {
  margin: 0 0 0px 0;
  padding: 0;
  list-style: none;
}

ul li {
  padding-left: 15px;
  position: relative;
}

ul li:before {
  content: ">";
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  left: 0;
  top: 0;
  color: #edb700;
}

.examplesBox {
  border: 1px solid gray;
  margin-bottom: 15px;
  padding: 10px 10px 10px 10px;
  color: white;
  background-image: url("../images/background.png");
  background-size: 100% 100%;
  background-position: center;
}

.hyperlink {
  text-decoration: underline;
  cursor: pointer;
}

.blueBackground {
  background: rgb(6, 14, 46);
}

.emailSignup input {
  width: 100%;
  margin-bottom: 10px;
}
