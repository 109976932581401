.pageHeader .navbar {
  margin-top: 20px;
  background-color: white;
  height: 60px;
  padding: 5px;
}

.pageHeader .logo {
  width: 70vw;
  max-width: 220px;
}

.pageHeader a {
  color: #23333a !important;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.15em;

  display: inline-block;
  padding: 15px 20px;
  position: relative;
}

.pageHeader a:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: orange;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.pageHeader a:hover:after {
  width: 100%;
  left: 0;
}

.pageHeader .active {
  color: rgb(255, 166, 0) !important;
}

.pageHeader .l100 {
  right: 0px !important;
}

.navbar-nav {
  margin-left: 15px;
}

.pageHeader .userImage {
  height: 44px;
  width: 34px;
  margin-left: 10px;
}
