.App {
  width: 100%;
  height: 100%;
  margin: 0px;
  position: relative;
  overflow-x: hidden;
}

.App .backLayer {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  overflow: hidden;
}

.App .frontLayer {
  z-index: 100;
  color: white;
}

.App .frontLayer_old {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  overflow-y: auto;
}

.App .contents {
  margin-top: 15px;
  margin-bottom: 15px;
}

.App .contactTextBox {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid white;
  padding: 5px 0px 5px 0px;
  margin-bottom: 20px;
}

.App .contactTextBox td {
  vertical-align: top;
  padding: 5px 10px 5px 10px;
  text-align: justify;
}
