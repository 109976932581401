.LoginPanel .BackToHomeSubNav {
  padding: 0px;
  padding-left: 5px;
  background-color: lightgray;
  cursor: pointer;
  text-align: left;
  margin-bottom: 10px;
}

.LoginPanel .BackToHomeSubNav:hover {
  background-color: rgb(82, 117, 199) !important;
  color: white;
}

.LoginPanel .GoogleButton {
  border: 1px solid gray;
}

.LoginPanel button {
  height: 44px;
}
