.dropArea {
  background-color: rgb(250, 250, 250);
  border: 2px dashed rgb(0, 0, 150);
  text-align: center;
  font-size: 25px;
  padding: 20px;
  color: gray;
}

.dropArea:hover {
  background-color: rgb(200, 200, 250);
}
