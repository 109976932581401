.ItemList {
  border: 1px solid black;
  padding: 10px;
}
.Item {
  border: 1px solid gray;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px 10px 0px 10px;
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.28);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.28);
}

.Item label {
  font-weight: bold;
}

.Item td {
  vertical-align: top;
}

.Item h4 {
  font-weight: bold;
}

.Item:hover {
  border: 1px solid red;
  background-color: rgba(255, 0, 0, 0.05);
}

.ItemIconColumn {
  float: left;
  width: 50px;
}

.ItemIconColumn img {
  width: 50px;
}

.ItemDescriptionColumn {
  margin-left: 60px;
}

.keyword {
  background-color: lightgray;
  padding: 0px 5px 0px 5px;
  margin-right: 5px;
  display: inline-block;
}
